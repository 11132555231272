@mixin absolute-fill {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.dashboard-body {
  height: 100vh;
  width: 100vw;

  display: grid;
  grid-template-areas: 
          "navbar navbar"
          "sidebar main";
  grid-template-rows: auto minmax(100px, 1fr);
  grid-template-columns: auto minmax(100px, 1fr);

  .navbar {
    grid-area: navbar;
  }

  .sidebar {
    grid-area: sidebar;
  }

  main {
    grid-area: main;
  }
}

.dashboard-container {
  display: grid;
  grid-template-areas: 
          "topbar"
          "schema"
          "forms";
  grid-template-rows: auto auto minmax(100px, 1fr);

  .dashboard-topbar {
    grid-area: topbar;
  }

  .dashboard-schema {
    grid-area: schema;
  }

  .dashboard-widgets {
    grid-area: forms;
  }
}

.dashboard-widgets {
  display: grid;
  grid-template-areas: "list form";
  grid-template-columns: 45em minmax(100px, 1fr);
  gap: 1em;
  padding: 0 1em 1em;

  .dashboard-widgets__alive {
    grid-area: list;
  }

  .dashboard-widgets__finished {
    grid-area: list;
  }

  .dashboard-widgets__form {
    grid-area: form;
    position: relative;

    > div {
      overflow: hidden;
      @include absolute-fill;
    }
  }
}

/* меняем размер */
.dashboard-widgets {
  font-size: 12px;

  .form-select, .form-control, .form-control-text {
    font-size: 12px;
  }

  .btn {
    --bs-btn-font-size: 12px;
  }

  .dashboard-widgets__alive-caption,
  .dashboard-widgets__alive-col1-header,
  .dashboard-widgets__alive-col2-header,
  .dashboard-widgets__finished-caption,
  .dash-form__caption {
    font-size: 16px;
    font-weight: normal;
  }

  .dashboard-widgets__alive-col1-header,
  .dashboard-widgets__alive-col2-header {
    padding-top: 10px;
    padding-bottom: 11px;
  }
}

.dashboard-widgets__alive {
  display: grid;

  grid-template-areas: 
      "caption caption"
      "col1-head col2-head"
      "col1-body col2-body"
      "footer footer";
  grid-template-columns: 50% 50%;
  grid-template-rows: auto auto minmax(0, 1fr) auto;

  .dashboard-widgets__alive-caption {
    grid-area: caption;
  }

  .dashboard-widgets__alive-col1-header {
    grid-area: col1-head;
  }

  .dashboard-widgets__alive-col1-body {
    grid-area: col1-body;
    position: relative;

    > div {
      overflow: auto;
      @include absolute-fill;
    }
  }

  .dashboard-widgets__alive-col2-header {
    grid-area: col2-head;
  }

  .dashboard-widgets__alive-col2-body {
    grid-area: col2-body;
    position: relative;

    > div {
      overflow: auto;
      @include absolute-fill;
    }
  }

  .dashboard-widgets__alive-footer {
    grid-area: footer;
  }
}

.dashboard-widgets__finished {
  display: grid;

  grid-template-areas: 
      "caption"
      "body";
  grid-template-rows: auto minmax(0, 1fr);

  .dashboard-widgets__finished-caption {
    grid-area: caption;
  }

  .dashboard-widgets__finished-body {
    grid-area: body;
    position: relative;

    > div {
      overflow: auto;
      @include absolute-fill;
    }
  }
}

.dash-form {
  display: grid;
  grid-template-areas: 
    "caption caption caption"
    "col1       col2       col3";
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) auto;
  grid-template-rows: auto 1fr;

  .dash-form__caption {
    grid-area: caption;
  }

  .dash-form__db-details {
    grid-area: col1;
  }

  .dash-form__form-details {
    grid-area: col2;
  }

  .dash-form__form-other {
    grid-area: col3;
    max-width: 38em;
  }

  table {
    margin: 0;
  }
}

.dash-form__db-details {
  display: grid;
  grid-template-areas: 
    "head"
    "caption"
    "table"
    "foot";
  grid-template-rows: auto auto 1fr auto;

  .dash-form__db-details-filter {
    grid-area: head;
  }

  .dash-form__db-details-caption {
    grid-area: caption;
  }

  .dash-form__db-details-table {
    grid-area: table;
  }

  .dash-form__db-details-foot {
    grid-area: foot;
  }
}

.dash-form__db-details-table {
  position: relative;

  > div {
    overflow: auto;
    @include absolute-fill;
  }
}

.dash-form__form-details {
  display: grid;
  grid-template-areas: 
    "head"
    "caption"
    "body"
    "foot";
  grid-template-rows: auto auto 1fr auto;

  .dash-form__form-type {
    grid-area: head;
  }

  .dash-form__form-details-caption {
    grid-area: caption;
  }

  .dash-form__form-details-table {
    grid-area: body;
  }

  .dash-form__form-details-foot {
    grid-area: foot;
  }
}

.dash-form__form-details-table {
  position: relative;

  > div {
    overflow: auto;
    @include absolute-fill;
  }
}

.dash-form__form-other {
  display: grid;
  grid-template-areas: 
    "head"
    "body"
    "foot";
  grid-template-rows: auto 1fr auto;

  .dash-form__form-other-head {
    grid-area: head;
  }

  .dash-form__form-other-body {
    grid-area: body;
  }

  .dash-form__form-other-foot {
    grid-area: foot;
  }
}

.dash-form__db-details-filter,
.dash-form__form-type,
.dash-form__form-other-head {
  height: 3.4em;
}

.dash-form__form-other-body {
}

.dashboard-card {
  .card-header {
    padding: .5rem 1rem;
    font-size: 1rem;
    background-color: #f0f0f0;
  }
}