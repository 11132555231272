.modal .modal-footer {
  border-top: none;
}

.modal-content-alert {
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);

  > .modal-body {
    padding-bottom: 0;
  }
}

.modal-content-alert-danger {
  --bs-alert-color: #842029;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f5c2c7;
  --bs-modal-header-border-color: #f5c2c7;
}

.modal-content-header-only {
  .modal-header {
    border-bottom: none;
  }
}