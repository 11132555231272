@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/Roboto/Roboto-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: normal;
  src: url('../fonts/Roboto/Roboto-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: bold;
  src: url('../fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/Roboto/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
}
