@media print {
  @page {
    size: auto;   /* auto is the current printer page size */
    margin: 2rem;  /* this affects the margin in the printer settings */
  }

  .print-no-col {
    flex: none !important;;
    width: auto !important;
    margin-left: 0 !important;
  }

  .col-print-12 {
    flex: 0 0 auto;
    width: 100%;
    margin-left: 0;
  }

  .form-text {
    display: none !important;
  }

  .form-select,
  .form-control {
    border: none;
  }

  .page-break {
    clear: both;
    page-break-after: always;
  }
}
