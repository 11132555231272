.container-row-widget {
  cursor: pointer;

  background: var(--svg-container-background);
  color: var(--svg-container-text-color);
  border-color: var(--svg-container-badge-background);

  .vr {
    background-color: var(--svg-container-badge-background);
  }

  [data-bind="number"],
  [data-bind="process"],
  [data-bind="coating"] {
    font-weight: bold;
  }
}