.report-one {
  font-size: 12px;
  width: 297mm;
  margin: 0 auto;

  .report-page {
    width: 297mm;
    padding: 2em 2em 2em 25mm;
    border: 1px dashed #cdcdcd;
  }

  tr > td,
  tr > th {
    padding: .5em;
  }

  .bold-border-cell {
    border: solid 2px #000;
  }

  .report-page-chart {
    margin-left: auto;
    margin-right: auto;

    display: grid;
    grid-template-areas: 
      "header"
      "chart-container";
    grid-template-rows: auto 1fr;

    > .report-page-title {
      grid-area: header;
    }

    > .report-page-chart-container {
      grid-area: chart-container;
    }
  }

  .report-page-chart-container {
    display: grid;
    grid-template-areas: 
      "header"
      "chart"
      "footer";
    grid-template-rows: auto 1fr auto;

    .report-chart-header {
      grid-area: header;
      font-size: 150%;
    }

    .report-chart {
      grid-area: chart;
    }

    .report-chart-footer {
      grid-area: footer;
    }

    .report-chart-axis-label {
      font-size: 150%;
      font-weight: bold;
    }
  }

  .report-chart {
    height: 120mm;

    svg {
      g.tick line {
        stroke: #ccc;
        stroke-opacity: 1;
      }
    }
  }
}

.report-one--highlight {
  color: #0409c9;
}

@media print {
  .report-one {
    padding: 0;
    width: auto;
    margin: 0;

    .report-page {
      padding: 0 0 0 25mm;
      width: auto;
      height: auto;
      border: none;
    }
  }
}