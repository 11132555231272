.popover {
  --bs-popover-zindex: 1045;
}

.popover.popover-header-only .popover-header {
  border-bottom-left-radius: var(--bs-popover-border-radius);
  border-bottom-right-radius: var(--bs-popover-border-radius);
}

.popover.popover-auto {
  max-width: none;
}

.popover.popover-dark {
  --bs-popover-bg: #000;
}