
.hidden {
  display: none !important;
}

.visibility-hidden {
  visibility: hidden;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.table-row-pointer tbody tr {
  cursor: pointer;
}

@for $i from 2 through 10 {
  .w-#{$i}em {
    width: #{$i}em;
  }
}

.pre-line {
  white-space: pre-line;
}

tr:first-child .muted-in-first-row {
  opacity: .1;
}

tr:last-child .muted-in-last-row {
  opacity: .1;
}

.swap-above {
  background-color: cadetblue;
}

.swap-below {
  background-color: aliceblue;
}

.narrow-form {
  max-width: 40em;
}

.form-label-strong label.form-label {
  font-weight: bold;
}

label.form-label,
label.col-form-label {
  color: #122776;
}

.title-highlight {
  color: #122776;
}

.fw-normal {
  font-weight: normal;
}

.cm-editor-form-control {
  .cm-editor {
    border: 1px solid #ced4da;
    border-radius: 0.375rem;

    &.cm-focused {
      border-color: #86b7fe;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
    }
  }

  .cm-scroller {
    border-radius: 0.375rem;
  }
}

.cb-svg-preview > svg {
  max-width: 100%;
  max-height: 100%;
}

.single-page-form,
.single-page-form-control {
  max-width: 50em;
}

.w-0 {
  width: 0;
}

.w-10em {
  width: 10em;
}

.w-5em {
  width: 5em;
}

.w-7em {
  width: 7em;
}

.min-h-100 {
  min-height: 100%;
}

.min-h-100vh {
  min-height: 100vh;
}

.max-w-70em {
  max-width: 70em;
}

.sticky-h1 {
  position: sticky;
  top: 48px;
  background: #fff;
  box-shadow: 0 0 5px #fff;
}

.table-hide1 {
  > thead > tr > th.table-hide1-hidden,
  > tbody > tr > td.table-hide1-hidden {
    display: none;
  }
}

.hide-group1-hide {
  .hide-group1 {
    display: none;
  }
}

.container-widget {
  min-width: 25em;
}

tr.dynamic-step-row {
  background: #f0f0f0;

  > td {
    border-bottom: solid 1px white;
  }
}

.overflow-ellipsis {
  text-overflow: ellipsis;
}

.vr {
  width: 1px;
}