html.login-page {
  width: 100%;
  height: 100%;

  body {
    width: 100%;
    height: 100%;

    background: rgb(0, 102, 161);
    background: linear-gradient(180deg, rgba(0, 102, 161, 1) 0%, rgba(12, 110, 253, 1) 100%);
  }
}

.form-signin-caption {
  max-width: 45em;
  margin: auto;

  padding: 10vh 0;
  text-align: center;
}

.form-signin {
  width: 100%;
  max-width: 45em;
  padding: 2em 5em;
  margin: auto;

  border-radius: 1em;
  background: #fff;
}

.form-signin .checkbox {
  text-align: center;
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
}

.form-signin input[type="password"] {
}