@mixin progress-buttons-back($color) {
  $color-divider: adjust-color($color, $whiteness: 30%);

  background: {
    image: linear-gradient(90deg,
            $color-divider,
            $color-divider 5%,
            $color 5%,
            $color 10%,
            $color-divider 10%,
            $color-divider 15%,
            $color 15%,
            $color 20%,
            $color-divider 20%,
            $color-divider 25%,
            $color 25%,
            $color 30%,
            $color-divider 30%,
            $color-divider 35%,
            $color 35%,
            $color 40%,
            $color-divider 40%,
            $color-divider 45%,
            $color 45%,
            $color 50%,
            $color-divider 50%,
            $color-divider 55%,
            $color 55%,
            $color 60%,
            $color-divider 60%,
            $color-divider 65%,
            $color 65%,
            $color 70%,
            $color-divider 70%,
            $color-divider 75%,
            $color 75%,
            $color 80%,
            $color-divider 80%,
            $color-divider 85%,
            $color 85%,
            $color 90%,
            $color-divider 90%,
            $color-divider 95%,
            $color 95%,
            $color 100%);
    size: 200% 100%;
  }

  animation: progressButtonBackAnimation 2000ms infinite linear;
}

@keyframes progressButtonBackAnimation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -100% 0;
  }
}

.btn-outline-primary.progress-button-back,
.btn-primary.progress-button-back {
  @include progress-buttons-back(#0d6efd);
  opacity: .85;
}

.btn-outline-secondary.progress-button-back,
.btn-secondary.progress-button-back {
  @include progress-buttons-back(#6c757d);
  opacity: .85;
}

.btn-outline-danger.progress-button-back,
.btn-danger.progress-button-back {
  @include progress-buttons-back(#dc3545);
  opacity: .85;
}

.btn-outline-success.progress-button-back,
.btn-success.progress-button-back {
  @include progress-buttons-back(#198754);
  opacity: .85;
}

.reloading-fade {
  position: relative;

  &::after {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: .2;

    @include progress-buttons-back(#888);
  }
}
