
.btn-toggle-nav a {
  display: inline-flex;
  padding: .1875rem .5rem;
  margin-top: .125rem;
  text-decoration: none;
}

.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
  background-color: rgba(36, 112, 220, 0.59);
}

.btn-toggle-nav a.active {
  background-color: rgba(36, 112, 220, 0.37);
}

.nav {
  --bs-nav-pills-link-active-bg: rgba(36, 112, 220, 0.79);
}
