body {
  --bs-body-font-family: "Roboto", sans-serif;
  --bs-body-font-weight: 300;
}

.btn {
  --bs-btn-font-family: "Roboto", sans-serif;
}

.input-group {
  > button {
    line-height: 1;
  }
}

.progress-with-text {
  position: relative;

  .progress {
    height: 1.6em;
  }

  .progress-bar {
    background: #03a9f4;
  }

  .progress-text {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    text-align: center;
    font-size: .9em;
    margin-top: -.45em;
    line-height: 1;
    text-shadow: 0 0 5px #fff;
  }
}

.me-n1 {
  margin-right: -.25rem;
}

.me-n2 {
  margin-right: -.5rem;
}

.mt-n1 {
  margin-top: -.25rem;
}

.mb-n2 {
  margin-bottom: -.5rem;
}

.btn-link.btn-link-dotted {
  text-decoration: underline dotted;
}

.btn-secondary {
  --bs-btn-color: #0a0a0a;
  --bs-btn-bg: #f2f2f2;
  --bs-btn-border-color: #f2f2f2;
  --bs-btn-box-shadow: 0px 1px 2px #76757580, 0px 2px 4px #8f8f8fbf;
  box-shadow: var(--bs-btn-box-shadow);
}

.btn-primary,
.btn-success,
.btn-danger,
.btn-warning {
  --bs-btn-border-color: #f2f2f22e;
  --bs-btn-box-shadow: 0px 1px 2px #76757580, 0px 2px 4px #8f8f8fbf;
  box-shadow: var(--bs-btn-box-shadow);
}

.input-group {
  .form-control {
    border-inline-end: none !important;
  }

  .btn-secondary.shadow-none {
    --bs-btn-border-color: #ced4da;
  }
}

.btn.disabled, .btn:disabled, fieldset:disabled .btn {
  --bs-btn-box-shadow: none;
}

.form-control.disabled {
  background-color: #e9ecef;
  opacity: 1;
}

.form-control-nofocus:focus {
  box-shadow: none;
  outline: none;
  border-color: #ced4da;
}

.form-control-text {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-clip: padding-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  border: 1px solid transparent;
}

tr:last-of-type.last-border-bottom-0,
tr:last-of-type.last-border-bottom-0 td {
  border-bottom-width: 0;
}

tr:first-of-type.first-border-top-0,
tr:first-of-type.first-border-top-0 td {
  border-top-width: 0;
}

.navbar.navbar-dark {
  background: #0066a3;
}

.navbar {
  .navbar-brand {
    background-color: unset;
    box-shadow: unset;
  }
}

.fa-stack.small {
  font-size: 0.5em;

  i {
    vertical-align: middle;
  }
}

.border-gray {
  --bs-border-opacity: 1;
  border-color: #d0d0d0 !important;
}

.table tr.stripe {
  background: #f0f0f0;
}
