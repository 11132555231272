@import "../../../../../node_modules/bootstrap-table/dist/bootstrap-table.min.css";
@import "bootstrap-table";

@import "bootstrap-autocomplete";
@import "bootstrap-toast";
@import "bootstrap-popover";
@import "bootstrap-modal";
@import "bootstrap-fixes";

@import "../../../../../node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker.css";

@import "progress-buttons";
@import "spinners";
@import "inputs";
@import "animations";
@import "fonts";

@import "layout";
@import "navigation";
@import "login-page";
@import "player";
@import "dashboard";
@import "container-row-widget";
@import "report-one";

@import "print";
@import "typo";