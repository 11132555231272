.blink-opacity {
  animation: blink-opacity-animation .75s steps(5, start) infinite;
  -webkit-animation: blink-opacity-animation .75s steps(5, start) infinite;
}

@keyframes blink-opacity-animation {
  from {
    opacity: 0.25;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0.25;
  }
}

@-webkit-keyframes blink-animation {
  from {
    opacity: 0.25;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0.25;
  }
}